import React from 'react';
import Typography from '@mui/material/Typography';
import { formatMoney } from '../helpers';

const displayAmount = (amount, coinType) => {
  if (['UF', 'UNIDAD DE FOMENTO (UF)'].includes(coinType)) return `UF ${amount}`;
  if (coinType === 'US') return `USD$${formatMoney(amount)}`;
  return `$${formatMoney(amount)}`;
};

const DicomDetailsColumns = [
  {
    field: 'doctoEntryDate',
    headerName: 'Fecha Docto',
    type: 'string',
    sortable: true,
    filterable: false,
    renderCell: ({ row }) => (
      <Typography>{row.doctoEntryDate}</Typography>
    ),
  },
  {
    field: 'expirationDate',
    headerName: 'Fecha Expiración',
    type: 'string',
    sortable: true,
    filterable: false,
    maxWidth: 120,
    renderCell: ({ row }) => (
      <Typography>{row.expirationDate}</Typography>
    ),
  },
  {
    field: 'amount',
    headerName: 'Monto Total',
    type: 'string',
    sortable: true,
    filterable: false,
    maxWidth: 90,
    renderCell: ({ row }) => (
      <Typography>{displayAmount(row.amount, row.coinType)}</Typography>
    ),
  },
  {
    field: 'debtType',
    headerName: 'Tipo Deuda',
    type: 'string',
    sortable: true,
    filterable: false,
    maxWidth: 120,
    renderCell: ({ row }) => (
      <Typography>{row.debtType}</Typography>
    ),
  },
  {
    field: 'doctoNumber',
    headerName: 'N. Documento',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 0.6,
    renderCell: ({ row }) => (
      <Typography>{row.doctoNumber}</Typography>
    ),
  },
  {
    field: 'documentType',
    headerName: 'Tipo Documento',
    type: 'string',
    sortable: true,
    filterable: false,
    maxWidth: 90,
    renderCell: ({ row }) => (
      <Typography>{row.documentType}</Typography>
    ),
  },
  {
    field: 'drawer',
    headerName: 'Librador',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <Typography>{row.drawer}</Typography>
    ),
  },
  {
    field: 'market',
    headerName: 'Mercado',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <Typography>{row.market}</Typography>
    ),
  },
];

export default DicomDetailsColumns;
