/* eslint-disable import/prefer-default-export */
import moment from 'moment';

export const diffDaysToTooltipText = (date) => {
  const today = moment().startOf('day');
  const daysDiff = date.diff(today, 'days');
  if (daysDiff < -1) {
    return 'Factura en mora';
  } if (daysDiff < 5) {
    return 'Factura por vencer';
  }
  return 'Factura al día';
};

export const diffDaysToShowIcon = (date) => {
  const today = moment().startOf('day');
  const daysDiff = date.diff(today, 'days');
  if (daysDiff < 5) {
    return true;
  }
  return false;
};
