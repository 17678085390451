const INVOICE_OPERATION_STATES_OPTIONS = [
  {
    label: 'Estado',
    value: 'All',
    key: 'All-statusFilter',
    filter: {
      status: [],
    },
  },
  {
    label: 'Procesando cesión',
    value: 'PROCESSING',
    key: 'Processing-statusFilter',
    filter: {
      status: ['Processing'],
    },
  },
  {
    label: 'Revisión documentos',
    value: 'PendingDocumentsReview',
    key: 'PendingDocumentsReview-statusFilter',
    filter: {
      status: ['PendingDocumentsReview'],
    },
  },
  {
    label: 'Por ratificar',
    value: 'PendingRatification',
    key: 'PendingRatification-statusFilter',
    filter: {
      status: ['PendingRatification'],
    },
  },
  {
    label: 'En curse',
    value: 'PendingTransfer',
    key: 'PendingTransfer-statusFilter',
    filter: {
      status: ['PendingTransfer'],
    },
  },
];

export default INVOICE_OPERATION_STATES_OPTIONS;
