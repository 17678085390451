import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Block from '@mui/icons-material/Block';
import { DicomDetailsCell } from '../components/cells';

const typeToString = {
  past: 'pasado',
  current: 'actual',
};

const DicomShareholdersColumns = [
  {
    field: 'name',
    headerName: 'Nombre',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Stack direction="row" spacing={1}>
        <Typography>{row.name}</Typography>
        {row.riskBlacklist && (
        <Tooltip title="Empresa en la Blacklist">
          <Block loading sx={{ width: 15, height: 15 }} color="error" />
        </Tooltip>
        )}
      </Stack>
    ),
  },
  {
    field: 'rut',
    headerName: 'Rut',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>
        {row.rut}-{row.dv}
      </Typography>
    ),
  },
  {
    field: 'participation',
    headerName: 'Participación',
    type: 'string',
    sortable: true,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => <Typography>{row.participation}%</Typography>,
  },
  {
    field: 'type',
    headerName: 'Estado',
    type: 'string',
    sortable: true,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => <Typography>{typeToString[row.type]}</Typography>,
  },
  {
    field: 'dateInformation',
    headerName: 'Fecha Información',
    type: 'string',
    sortable: true,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.dateInformation ?? 'n/d'}</Typography>
    ),
  },
  {
    field: 'dateVinculation',
    headerName: 'Fecha Vinculación',
    type: 'string',
    sortable: true,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.dateVinculation ?? 'n/d'}</Typography>
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',
    sortable: true,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => <Typography>{row.email ?? 'n/d'}</Typography>,
  },
  {
    field: 'dicomDetails',
    headerName: 'Dicom',
    type: 'singleSelect',
    align: 'left',
    renderCell: ({ row }) => (
      <DicomDetailsCell masterEntity={row?.relatedMasterEntity} />
    ),
    flex: 1,
  },
];

export default DicomShareholdersColumns;
