const INVOICE_CESSION_STATES_OPTIONS = [
  {
    label: 'Vigentes',
    value: 'OperatedStatuses',
    key: 'PendingTransfer-statusFilter-cession',
    filter: {
      status: ['Processing', 'PendingDocumentsReview', 'PendingRatification', 'PendingTransfer', 'Transfered', 'Debt'],
    },
  },
  {
    label: 'Históricas',
    value: 'Finished',
    key: 'Finished-statusFilter-cession',
    filter: {
      status: ['Finished'],
    },
  },
];

export default INVOICE_CESSION_STATES_OPTIONS;
