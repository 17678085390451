import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PsychologyIcon from '@mui/icons-material/Psychology';
import HourglassBottom from '@mui/icons-material/HourglassBottom';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GRID_DATE_COL_DEF } from '@mui/x-data-grid';
import { t } from 'i18next';
import reduce from 'lodash/reduce';
import moment from 'moment';
import React, { useMemo } from 'react';
import {
  ActionDrawerCell,
  AmountWithIvaCell,
  AmountToCommercialCollectCell,
  CompanyCell,
  FactoringStatusCell,
  FolioInvoiceProfileCell,
  InvoiceCessionsTooltip,
  MasterEntityNameCell,
  TextCell,
  PaymentAmountAfterNegativeSurplusCell,
  PendingDocumentsManagerCell,
} from '../components/cells';
import DateCell from '../components/cells/DateCell';
import FolioCell from '../components/cells/FolioCell';
import DocumentsCell from '../components/cells/DocumentsCell';
import OperationalAlertsArray from '../components/cells/OperationalAlertsArray';
import {
  AvatarCell,
  AvatarExecutiveAssignedCell,
  CessionStatus,
  CollectionPriorityCell,
  DateToPayEditCell,
  DateWithDaysDiffCell,
  Status,
  StatusSummaryStepper,
  OfferTransferConditionRatificationCell,
} from '../components/dataGridCells';
import FingoGridNode from '../components/dataGrids/FingoGridNode';
import CollectionActions from '../components/drawer/CollectionActions';
import DrawerHeader from '../components/drawer/DrawerHeader';
import UserActions from '../components/drawer/UserActions';
import TooltipTypography from '../components/typography/TooltipTypography';
import { formatMoney, getTimeDiffText } from '../helpers';
import { useIsMobile, useSelectedCompany } from '../hooks';
import useWidth from './document-columns-width';
import { UploadDocumentButton } from '../components/buttons';

// eslint-disable-next-line react/prop-types

const useInvoicePreColumns = (customInvoiceColumns = []) => {
  const formatRate = (row, offerType, rateType) => `${row[offerType][rateType]?.toFixed(2)?.replace('.', ',')}%`;
  const rateStatus = (row, offerType, rateType) => {
    if (row[offerType]) {
      if (
        offerType === 'preoffer'
        && !row.preofferSelectable?.selectable
        && !['EVALUATING', 'PENDINGDOCUMENTS'].includes(row.preoffer?.preofferevaluationrequest?.status)
      ) {
        return 'N/D';
      }
      return formatRate(row, offerType, rateType);
    }
    return 'N/D';
  };

  const orderingRateStatus = (row, offerType, rateType) => {
    if (row[offerType]) {
      return `${row[offerType][rateType]?.toFixed(2)?.replace('.', ',')}%`;
    }
    return 'N/D';
  };
  const debtDays = (invoice) => {
    if (invoice.status === 'FINISHED') {
      const days = moment(invoice.paymentDate).diff(
        moment(invoice.dateToPay),
        'days',
      );
      return days < 0 ? 0 : days;
    }
    return invoice.invoiceDebt?.days || 0;
  };

  const isMobile = useIsMobile();
  const selectedCompany = useSelectedCompany();
  const {
    rateWidth,
    amountWidth,
    dateInputWidth,
    dateWidth,
    rutWidth,
    iconSize,
    statusWidth,
    cessionStatusWidth,
  } = useWidth();
  const invoiceDefaultPreColumns = [
    {
      field: 'textFolio',
      type: 'string',
      headerName: 'Folio',
      sortable: false,
      filterable: false,
      align: 'center',
      valueGetter: (params) => params.row.folio,
      renderCell: ({ row }) => <TextCell text={row.folio} color="primary" />,
      width: 60,
    },
    {
      field: 'simpleFolio',
      type: 'string',
      headerName: 'Folio',
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <InvoiceCessionsTooltip invoice={row}>
          <span>
            <TextCell text={row.folio} />
          </span>
        </InvoiceCessionsTooltip>
      ),
      width: 80,
    },
    {
      field: 'folio',
      headerName: 'Folio',
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      width: isMobile ? 55 : 110,
      renderCell: ({ row }) => (
        <FolioCell
          invoice={row}
          showLightningFastPayment={
            selectedCompany?.masterEntity.rut
              === row.company?.masterEntity.rut && row.lightningPaymentAuthorized
          }
        />
      ),
    },
    {
      field: 'folio-invoice-profile',
      headerName: 'Folio',
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      minWidth: 100,
      filterable: false,
      renderCell: ({ row }) => (
        <FolioInvoiceProfileCell
          invoice={row}
          AlertsArray={OperationalAlertsArray}
        />
      ),
    },
    {
      field: 'dteType_Code',
      headerName: t('dte'),
      type: 'singleSelect',
      valueOptions: [
        { label: 'Factura Electronica', value: 33 },
        { label: 'Factura Exenta Electronica', value: 34 },
        // { label: 'Liquidacion Factura Electronica', value: 43 },
        // { label: 'Factura de Compra Electronica', value: 46 },
        // { label: 'Nota de Credito de Exportacion Electronica', value: 112 },
        { label: 'Nota de Credito Electronica', value: 61 },
        { label: 'Nota de Debito Electronica', value: 56 },
      ],
      // { label: 'Guia de Despacho Electronica', value: 52 },
      // { label: 'Factura de Exportacion Electronica', value: 110 },
      // { label: 'Nota de Debito de Exportacion Electronica', value: 111 }],
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start">
          <Typography variant="body2">{params.row.dteType.name}</Typography>
        </Grid>
      ),
    },
    {
      field: 'receptionDate',
      width: dateWidth,
      type: 'dateTime',
      headerName: 'Recepción SII',
      renderCell: (params) => (
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          direction="column"
        >
          <Typography variant="body2">
            {params.row.receptionDate.format('DD-MM-YYYY')}
          </Typography>
          <Typography variant="caption">
            {getTimeDiffText(params.row.receptionDate)}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'company_MasterEntity_Name',
      headerName: 'Emisor',
      type: 'string',
      flex: 1,
      renderCell: (params) => (
        <MasterEntityNameCell
          masterEntity={params.row.company.masterEntity}
          loanedStatus={params.row.loanedStatus}
          loanedTo={params.row.loanedTo}
        />
      ),
    },
    {
      field: 'company_MasterEntity_Rut',
      headerName: 'Rut emisor',
      type: 'string',
      width: rutWidth,
      renderCell: ({ row }) => (
        <CompanyCell company={row.company.masterEntity} />
      ),
    },
    {
      field: 'company_Name',
      type: 'string',
      headerName: 'Empresa',
      renderCell: ({ row }) => (
        <CompanyCell company={row?.company.masterEntity} />
      ),
      sortable: true,
      flex: 2,
    },
    {
      field: 'receiver_Name_Loaned',
      headerName: 'Empresa',
      type: 'string',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <MasterEntityNameCell
          masterEntity={params.row.receiver}
          loanedStatus={params.row.loanedStatus}
          loanedTo={params.row.loanedTo}
        />
      ),
    },
    {
      field: 'receiver_sortable',
      headerName: 'Receptor',
      type: 'string',
      flex: 2,
      renderCell: ({ row }) => <CompanyCell company={row.receiver} />,
    },
    {
      field: 'confirmingReceiver_Name',
      headerName: 'Receptor',
      type: 'string',
      flex: 2,
      renderCell: ({ row }) => (
        <CompanyCell company={row.receiver.masterEntity} />
      ),
    },
    {
      field: 'debtDays',
      headerName: 'Atraso',
      type: 'string',
      width: rateWidth,
      sortable: false,
      renderCell: (params) => (
        <Typography variant="body2">{debtDays(params.row)}</Typography>
      ),
    },
    {
      field: 'dateToPay',
      headerName: isMobile ? 'Fecha' : 'Fecha de pago',
      ...GRID_DATE_COL_DEF,
      width: dateInputWidth,
      filterable: false,
      sortable: false,
      resizable: false,
      renderEditCell: (params) => <DateToPayEditCell params={params} />,
      renderCell: (params) => <DateToPayEditCell params={params} />,
    },
    {
      field: 'paymentDays',
      headerName: 'Plazo',
      width: 100,
      valueGetter: (params) => params.row.dateToPay.diff(params.row.dateIssued, 'days'),
      valueSetter: (params) => ({
        ...params.row,
        dateToPay: params.row.dateIssued.clone().add(params.value, 'days'),
      }),
      renderCell: (params) => (
        <Typography>
          {getTimeDiffText(params.row.dateToPay, params.row.dateIssued)}
        </Typography>
      ),
    },
    {
      field: 'operationDateToPay',
      type: 'date',
      headerName: 'Vencimiento',
      ...GRID_DATE_COL_DEF,
      width: 140,
      sortable: false,
      resizable: false,
      renderCell: (params) => (
        <DateWithDaysDiffCell
          date={params.row.dateToPay}
          status={params.row.status}
          useColors
        />
      ),
    },
    {
      field: 'offerDateToPay',
      headerName: isMobile ? 'Fecha' : 'Fecha de pago',
      ...GRID_DATE_COL_DEF,
      width: dateInputWidth,
      filterable: false,
      sortable: false,
      resizable: false,
      renderCell: ({ row }) => {
        const dateToPay = row?.dateToPay;
        const paymentDate = row.preoffer?.preofferevaluationrequest?.paymentDate;
        const date = moment.isMoment(dateToPay) && dateToPay.isValid()
          ? dateToPay
          : paymentDate;
        return <DateCell date={date} />;
      },
    },
    {
      field: 'dateExpiration',
      headerName: isMobile ? 'Fecha' : 'Fecha Expiracion',
      ...GRID_DATE_COL_DEF,
      width: dateInputWidth,
      filterable: false,
      sortable: true,
      resizable: false,
      renderCell: ({ row }) => <DateCell date={row.dateExpiration} />,
    },
    {
      field: 'dateIssued',
      width: dateWidth,
      type: 'date',
      headerName: 'Emisión',
      filterable: false,
      renderCell: ({ row }) => <DateCell date={row.dateIssued} />,
    },
    {
      field: 'operationDate',
      headerName: isMobile ? 'Fecha' : 'Fecha Operacion',
      ...GRID_DATE_COL_DEF,
      width: dateInputWidth,
      filterable: false,
      sortable: false,
      resizable: false,
      renderCell: ({ row }) => <DateCell date={row.dateOperation} />,
    },
    {
      field: 'amountWithIva',
      headerName: 'Monto',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: amountWidth,
      valueGetter: (params) => params.row.amountWithIva.amount,
      valueSetter: (params) => ({
        ...params.row,
        amountWithIva: { ...params.row.amountWithIva, amount: params.value },
      }),
      renderCell: ({ row }) => <AmountWithIvaCell row={row} />,
    },
    {
      field: 'amountWithIvaAndDebt',
      headerName: 'Monto',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: amountWidth + 35,
      valueGetter: (params) => params.row.amountWithIva.amount,
      valueSetter: (params) => ({
        ...params.row,
        amountWithIva: { ...params.row.amountWithIva, amount: params.value },
      }),
      renderCell: ({ row }) => <AmountToCommercialCollectCell row={row} />,
    },
    {
      field: 'paymentAmountAfterNegativeSurplus',
      headerName: 'Monto a transferir',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      width: amountWidth + 35,
      renderCell: ({ row }) => (
        <PaymentAmountAfterNegativeSurplusCell
          money={
            row.paymentDiscounts?.paymentAmountAfterNegativeSurplus
          }
        />
      ),
    },
    {
      field: 'preoffer_MonthlyRate',
      headerName: 'Tasa',
      type: 'number',
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params) => params.row.preoffer?.monthlyRate,
      valueSetter: (params) => ({
        ...params.row,
        preoffer: { ...params.row.preoffer, monthlyRate: params.value },
      }),
      renderCell: (params) => (
        <Typography variant="body2" align="left" width="100%">
          {rateStatus(params.row, 'preoffer', 'monthlyRate')}
        </Typography>
      ),
    },
    {
      field: 'rawPreofferMonthlyRate',
      headerName: 'Tasa',
      type: 'number',
      width: rateWidth + 15,
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params) => params.row.preoffer.monthlyRate,
      valueSetter: (params) => ({
        ...params.row,
        preoffer: { ...params.row.preoffer, monthlyRate: params.value },
      }),
      renderCell: (params) => (
        <Typography variant="body2" align="left" width="100%">
          {formatRate(params.row, 'preoffer', 'monthlyRate')}
        </Typography>
      ),
    },
    {
      field: 'confirmingAmountToPay',
      headerName: 'Monto a pagar',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row }) => (
        <Typography variant="body2" align="left" width="100%">
          $
          {formatMoney(
            reduce(
              row.relatedConfirming.actualAmortizationSchedule?.installments.map(
                ({ totalInterest, totalAmortization, totalDebtInterest }) => (
                  totalInterest + totalAmortization + totalDebtInterest),
              ),
              (memo, num) => memo + num,
              0,
            ),
          )}
        </Typography>
      ),
    },
    {
      field: 'defaultRate',
      headerName: 'Mora',
      type: 'number',
      sortable: false,
      filterable: false,
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2" align="left">
          {rateStatus(params.row, 'preoffer', 'defaultRate')}
        </Typography>
      ),
    },
    {
      field: 'retentionRate',
      headerName: 'Retención',
      type: 'number',
      sortable: false,
      filterable: false,
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2" align="left">
          {rateStatus(params.row, 'preoffer', 'retentionRate')}
        </Typography>
      ),
    },
    {
      field: 'offerMonthlyRate',
      headerName: 'Tasa',
      type: 'number',
      sortable: false,
      filterable: false,
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2">
          {rateStatus(params.row, 'offer', 'monthlyRate')}
        </Typography>
      ),
    },
    {
      field: 'offerDefaultRate',
      headerName: 'Mora',
      type: 'number',
      sortable: false,
      filterable: false,
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2">
          {rateStatus(params.row, 'offer', 'defaultRate')}
        </Typography>
      ),
    },
    {
      field: 'offerRetentionRate',
      headerName: 'Retención',
      type: 'number',
      sortable: false,
      filterable: false,
      width: rateWidth,
      minWidth: rateWidth + 20,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Typography variant="body2">
          {rateStatus(params.row, 'offer', 'retentionRate')}
        </Typography>
      ),
    },
    {
      field: 'offerTransferCondition',
      headerName: 'Ratificación',
      type: 'string',
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <OfferTransferConditionRatificationCell offer={params.row.offer} />
      ),
    },
    {
      field: 'orderingMonthlyRate',
      headerName: 'Tasa',
      type: 'number',
      selectable: false,
      sortable: false,
      filterable: false,
      widht: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row }) => (
        <Typography variant="body2">
          {orderingRateStatus(
            row,
            row.offer ? 'offer' : 'preoffer',
            'monthlyRate',
          )}
        </Typography>
      ),
    },
    {
      field: 'orderingDefaultRate',
      headerName: 'Mora',
      type: 'number',
      selectable: false,
      sortable: false,
      filterable: false,
      widht: rateWidth,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row }) => (
        <Typography variant="body2">
          {orderingRateStatus(
            row,
            row.offer ? 'offer' : 'preoffer',
            'defaultRate',
          )}
        </Typography>
      ),
    },
    {
      field: 'siiStatus',
      headerName: 'Estado',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      width: statusWidth,
      type: 'singleSelect',
      align: 'center',
      renderCell: ({ row }) => <StatusSummaryStepper invoice={row} />,
    },
    {
      field: 'factoringStatus',
      headerName: 'Estado',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      filterable: false,
      width: 130,
      renderCell: ({ row }) => <FactoringStatusCell row={row} />,
    },
    {
      field: 'cessionStatus',
      headerName: 'Estado de operación',
      headerAlign: 'left',
      sortable: false,
      filterable: false,
      width: cessionStatusWidth,
      align: 'center',
      renderCell: (params) => <CessionStatus invoice={params.row} />,
    },
    {
      field: 'simpleStatus',
      headerName: 'Estado',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      width: statusWidth,
      renderCell: (params) => (
        <Status
          status={params.row.status}
          paymentDate={params.row.paymentDate}
          oneStepper
        />
      ),
    },
    {
      field: 'uploadDocuments',
      headerName: '',
      sortable: false,
      filterable: false,
      width: iconSize,
      valueGetter: ({ row }) => row.id,
      renderCell: ({ value }) => <UploadDocumentButton invoiceId={value} />,
    },
    {
      field: 'uploadDocumentsCommercial',
      headerName: '',
      sortable: false,
      filterable: false,
      width: 230,
      renderCell: ({ row }) => <DocumentsCell invoiceId={row?.id} documents={row?.documents} />,
    },
    {
      field: 'agreedDateToPay',
      headerName: 'Fecha de pago',
      sortable: false,
      filterable: false,
      width: 130,
      valueGetter: (params) => params.row.collectionManager?.currentDataForCollection?.dateToPay,
      renderCell: ({ value }) => (
        <DateWithDaysDiffCell date={value} useColors />
      ),
    },
    {
      field: 'executiveAssigned',
      headerName: 'Ejecutivo',
      filterable: false,
      sortable: false,
      width: 80,
      valueGetter: (params) => params.row.company.executiveAssigned?.firstName,
      renderCell: (params) => (
        <AvatarExecutiveAssignedCell
          companyId={params.row.company.id}
          user={params.row.company.executiveAssigned}
          assignationType="executive"
        />
      ),
    },
    {
      field: 'collectionManagerRelation_Collector',
      headerName: 'Cobradora',
      filterable: false,
      sortable: false,
      maxWidth: 90,
      flex: 1,
      renderCell: (params) => (
        <AvatarCell user={params.row.collectionManager?.collector} />
      ),
    },
    {
      field: 'collectionManagerRelation_CollectionPriority_Value',
      headerName: 'Prioridad',
      sortable: true,
      filterable: true,
      maxWidth: 80,
      flex: 1,
      valueGetter: (params) => params.row.collectionManager.collectionPriority.value,
      renderCell: (params) => (
        <CollectionPriorityCell
          priority={params.row.collectionManager.collectionPriority.value}
        />
      ),
    },
    {
      field: 'ratificationmanager_RatificationPriority_Value',
      headerName: 'Prioridad',
      sortable: true,
      filterable: true,
      maxWidth: 80,
      flex: 1,
      valueGetter: (params) => params.row.ratificationmanager?.ratificationPriority?.value,
      renderCell: (params) => (
        <CollectionPriorityCell priority={params.value} />
      ),
    },
    {
      field: 'actionsDrawer',
      headerName: 'Cobranza',
      type: 'string',
      sortable: false,
      width: 80,
      renderCell: ({ row }) => (
        <ActionDrawerCell
          title={`Gestiones de cobranza factura ${row.folio}`}
          actions={
            row.collectionManager
              ? row.collectionManager.actions.filter(
                (action) => action.actionType !== 'OTHER',
              )
              : []
          }
          headerComponent={DrawerHeader}
          contentComponent={UserActions}
          drawerId={row.folio}
        />
      ),
    },
    {
      field: 'ratificationActions',
      headerName: 'Ratificación',
      type: 'string',
      sortable: false,
      width: 80,
      renderCell: ({ row }) => (
        <ActionDrawerCell
          title={`Gestiones de ratificación factura ${row.folio}`}
          actions={
            row.ratificationmanager ? row.ratificationmanager.actions : []
          }
          headerComponent={DrawerHeader}
          contentComponent={CollectionActions}
          drawerId={row.id}
        />
      ),
    },
    {
      field: 'ratificationmanager_Ratificator_LastName',
      headerName: 'Ratificador',
      type: 'string',
      sortable: true,
      width: 80,
      renderCell: (params) => (
        <AvatarCell user={params.row.ratificationmanager.ratificator} />
      ),
    },
    {
      field: 'preoffer_Preofferevaluationrequest_pendingDocuments',
      headerName: 'Docs. Pendientes',
      type: 'string',
      align: 'center',
      sortable: false,
      width: 120,
      renderCell: ({ row }) => <PendingDocumentsManagerCell invoice={row} type="Risk" />,
    },
    {
      field: 'offer_pendingDocuments',
      headerName: 'Condiciones de giro',
      type: 'string',
      align: 'center',
      sortable: false,
      width: 120,
      renderCell: ({ row }) => <PendingDocumentsManagerCell invoice={row} type="Operations" />,
    },
    {
      field: 'preoffer_Preofferevaluationrequest_Status',
      headerName: 'Estado',
      type: 'string',
      sortable: false,
      width: 120,
      renderCell: (params) => {
        const { status } = params.row.preoffer.preofferevaluationrequest;
        if (status === 'OFFERED') {
          return (
            <Stack direction="row" alignItems="center">
              <TooltipTypography title="Aceptada">
                <CheckCircleIcon color="success" />
              </TooltipTypography>
              <Typography>Aceptada</Typography>
            </Stack>
          );
        }
        if (status === 'REJECTED') {
          return (
            <Stack direction="row" alignItems="center">
              <TooltipTypography title="Rechazada">
                <CancelIcon color="error" />
              </TooltipTypography>
              <Typography>Rechazada</Typography>
            </Stack>
          );
        }
        if (status === 'PENDINGDOCUMENTS') {
          return (
            <Stack direction="row" alignItems="center">
              <TooltipTypography title="Pendiente de Documentos">
                <HourglassBottom color="primary" />
              </TooltipTypography>
              <Typography>Pendiente de Documentos</Typography>
            </Stack>
          );
        }
        return (
          <Stack direction="row" alignItems="center">
            <TooltipTypography title="Evaluando">
              <PsychologyIcon color="info" />
            </TooltipTypography>
            <Typography>{status === 'DOCUMENTSREVIEWED' ? 'Revisión de Documentos' : 'Evaluando'}</Typography>
          </Stack>
        );
      },
    },
    {
      field: 'preoffer_Preofferevaluationrequest_RejectionReasonLabel',
      headerName: 'Rechazo',
      type: 'string',
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <TooltipTypography noWrap>
          {params.row.preoffer.preofferevaluationrequest.status
            === 'REJECTED'
            && params.row.preoffer.preofferevaluationrequest.rejectionReasonLabel}
        </TooltipTypography>
      ),
    },
    {
      field: 'preoffer_Preofferevaluationrequest_AssignedEvaluator_FirstName',
      headerName: 'Evaluador',
      type: 'string',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <AvatarCell
          user={params.row.preoffer.preofferevaluationrequest.assignedEvaluator}
        />
      ),
    },
    {
      field: 'preoffer_Preofferevaluationrequest_Ratification',
      headerName: 'Ratificación',
      type: 'string',
      sortable: false,
      width: 170,
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.preoffer.preofferevaluationrequest.ratification}
        </Typography>
      ),
    },
  ];
  return useMemo(() => {
    const invoicePreColumns = invoiceDefaultPreColumns.map(
      (defaultColumn) => customInvoiceColumns.find(
        (customColumn) => customColumn.field === defaultColumn.field,
      ) || defaultColumn,
    );
    const newPreColumns = customInvoiceColumns.filter(
      (customColumn) => !invoiceDefaultPreColumns
        .map((defaultColumn) => defaultColumn.field)
        .includes(customColumn.field),
    );
    const columns = [...invoicePreColumns, ...newPreColumns];
    return columns.map(({ renderCell, ...restParams }) => {
      if (renderCell) {
        return {
          ...restParams,
          renderCell: (params) => (
            <FingoGridNode params={params} renderCell={renderCell} />
          ),
        };
      }
      return restParams;
    });
  }, [isMobile]);
};

export default useInvoicePreColumns;
