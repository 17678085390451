import React from 'react';
import Typography from '@mui/material/Typography';

const DicomSocietiesColumns = [
  {
    field: 'name',
    headerName: 'Nombre',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.name}</Typography>
    ),
  },
  {
    field: 'rut',
    headerName: 'Rut',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.rut}-{row.dv}</Typography>
    ),
  },
  {
    field: 'capital',
    headerName: 'Capital',
    type: 'string',
    sortable: true,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.capital ?? 'n/d'}</Typography>
    ),
  },
  {
    field: 'beginDate',
    headerName: 'Fecha Inicio',
    type: 'string',
    sortable: true,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.beginDate ?? 'n/d'}</Typography>
    ),
  },
];

export default DicomSocietiesColumns;
