import FingoIcon from '../components/icons/FingoIcon';
import SantanderIcon from '../components/icons/SantanderIcon';
import getCdnUrl from '../helpers/get-cdn-url';

const SantanderImage = getCdnUrl(
  '/common_assets/assets/icons/santander_image.png',
);
const FingoImage = getCdnUrl('/common_assets/assets/icons/fingo_image.png');

export const REQUESTING_PLATFORM_TO_IMAGE_URL = {
  SANTANDER: SantanderImage,
  FINGO: FingoImage,
};

export const REQUESTING_PLATFORM_TO_ICON = {
  SANTANDER: SantanderIcon,
  FINGO: FingoIcon,
};

export const REQUESTING_PLATFORM_TO_CODES = {
  SANTANDER: 'SANTANDER',
  FINGO: 'FINGO',
};
