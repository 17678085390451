import React from 'react';
import Typography from '@mui/material/Typography';
import { CompanyProfileCell } from '../components/cells';
import { formatYearMonthDay } from '../helpers';

const useAmortizationScheduleColumns = () => [
  {
    id: 'id',
    field: 'id',
    headerName: 'ID',
    type: 'string',
    sortable: true,
    filterable: false,
    width: 120,
    renderCell: (params) => (
      <Typography
        variant="body1"
      >
        {params.row.id}
      </Typography>
    ),
  },
  {
    id: 'masterEntity_Name',
    field: 'masterEntity_Name',
    headerName: 'Cliente',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <CompanyProfileCell masterEntity={params.row.masterEntity} />
    ),
  },
  {
    id: 'interestRate',
    field: 'interestRate',
    headerName: 'Tasa',
    type: 'string',
    sortable: false,
    filterable: false,
    width: 90,
    renderCell: (params) => (
      <Typography variant="body1">
        {params.row.interestRate}
      </Typography>
    ),
  },
  {
    id: 'defaultRate',
    field: 'defaultRate',
    headerName: 'Mora',
    type: 'string',
    sortable: false,
    filterable: false,
    width: 60,
    renderCell: (params) => (
      <Typography variant="body1">
        {params.row.defaultRate}
      </Typography>
    ),
  },
  {
    id: 'contentType_Model',
    field: 'contentType_Model',
    headerName: 'Producto',
    type: 'string',
    sortable: true,
    filterable: false,
    width: 60,
    renderCell: (params) => {
      const model = params.row.contentType?.model;
      let product;
      switch (model) {
        case 'invoice':
          product = 'Factoring';
          break;
        case 'confirming':
          product = 'Confirming';
          break;
        case 'advancepayment':
          product = 'Adelanto';
          break;
        case 'purchaseorder':
          product = 'Ordering';
          break;
        default:
          product = '-';
      }
      return (
        <Typography variant="body1">
          {product}
        </Typography>
      );
    },
  },
  {
    id: 'createdAt',
    field: 'createdAt',
    headerName: 'Fecha de creación',
    type: 'string',
    sortable: false,
    filterable: false,
    width: 70,
    renderCell: (params) => (
      <Typography variant="body1">
        {formatYearMonthDay(params.row.createdAt)}
      </Typography>
    ),
  },
];

export default useAmortizationScheduleColumns;
