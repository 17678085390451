import React from 'react';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { formatMoney, formatYearMonthDay } from '../helpers';

const useInstallmentColumns = () => [
  {
    id: 'id',
    field: 'id',
    headerName: 'ID',
    type: 'string',
    sortable: true,
    filterable: false,
    width: 80,
    renderCell: (params) => (
      <Typography
        variant="body1"
      >
        {params.row.id}
      </Typography>
    ),
  },
  {
    id: 'installmentAmountToPay',
    field: 'installmentAmountToPay',
    headerName: 'Monto original',
    type: 'string',
    sortable: false,
    filterable: false,
    width: 120,
    renderCell: (params) => (
      <Typography
        variant="body1"
      >
        ${formatMoney(params.row.installmentAmountToPay)}
      </Typography>
    ),
  },
  {
    id: 'installmentManager_DebtAmount',
    field: 'installmentManager_DebtAmount',
    headerName: 'Monto mora',
    type: 'string',
    sortable: false,
    filterable: false,
    width: 120,
    renderCell: (params) => (
      <Typography
        variant="body1"
      >
        ${formatMoney(params.row.installmentManager.debtAmount)}
      </Typography>
    ),
  },
  {
    id: 'installmentManager_totalAmountToPay',
    field: 'installmentManager_totalAmountToPay',
    headerName: 'Monto cuota',
    type: 'string',
    sortable: false,
    filterable: false,
    width: 120,
    renderCell: (params) => (
      <Typography
        variant="body1"
      >
        ${formatMoney(params.row.installmentManager.totalAmountToPay)}
      </Typography>
    ),
  },
  {
    id: 'expirationDate',
    field: 'expirationDate',
    headerName: 'Expiración',
    type: 'string',
    sortable: true,
    filterable: false,
    width: 120,
    renderCell: (params) => (
      <Typography
        variant="body1"
      >
        {params.row.expirationDate ? formatYearMonthDay(params.row.expirationDate) : '-'}
      </Typography>
    ),
  },
  {
    id: 'installmentManager_PaymentDatetime',
    field: 'installmentManager_PaymentDatetime',
    headerName: 'Fecha de pago',
    type: 'string',
    sortable: true,
    filterable: false,
    width: 120,
    renderCell: (params) => (
      <Typography
        variant="body1"
      >
        {params.row.installmentManager.paymentDatetime ? formatYearMonthDay(params.row.installmentManager.paymentDatetime) : '-'}
      </Typography>
    ),
  },
  {
    id: 'installmentManager_Status',
    field: 'installmentManager_Status',
    headerName: 'Estado',
    type: 'string',
    sortable: false,
    filterable: false,
    width: 180,
    renderCell: (params) => {
      const { status } = params.row.installmentManager;
      if (status === 'PREVIEW') {
        return <VisibilityIcon color="info" />;
      }
      if (status === 'ACTIVE') {
        return <PendingIcon color="info" />;
      }
      if (status === 'RENEGOTIATED') {
        return <PendingIcon color="info" />;
      }
      if (status === 'FINISHED') {
        return <CheckCircleIcon color="success" />;
      }
      if (status === 'PAID_WITH_CONDONATION') {
        return <CheckCircleIcon color="success" />;
      }
      if (status === 'DEBT') {
        return <WarningIcon color="error" />;
      }
      return null;
    },
  },
];

export default useInstallmentColumns;
