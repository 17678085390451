const helpVideoLinks = {
  'available-factoring': 'AxXdJXyUuqM',
  ordering: '232nK8vl3Ec',
  'kobros-main-tutorial': 'eCkIds8GOiQ',
  'kobros-send-collection-mail': 'dpPCdZLIpuc',
  'kobros-add-data-for-collection': 'dpPCdZLIpuc',
  'kobros-collect-invoice': 'VWrSFeKOlOY',
  'kobros-add-collection-action': 'rI4LVVRnQb4',
};

const getHelpVideoLink = (type) => helpVideoLinks[type];

const helpTooltipText = {
  'available-factoring': ' te mostramos el paso a paso para que puedas anticipar tus facturas fácilmente',
  ordering: ' te mostramos el paso a paso para que puedas anticipar tus órdenes de compra fácilmente',
};

const getHelpTooltipText = (type) => helpTooltipText[type];

export { getHelpVideoLink, getHelpTooltipText };
