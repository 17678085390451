/* eslint-disable import/prefer-default-export */
import { useIsMobile } from '../hooks';

const useWidth = () => {
  const isMobile = useIsMobile();
  const rateWidth = 55;
  const amountWidth = isMobile ? 80 : 110;
  const dateInputWidth = isMobile ? 93 : 135;
  const statusWidth = isMobile ? 120 : 200;
  const orderingOperationStatus = isMobile ? 120 : 315;
  const cessionStatusWidth = isMobile ? 160 : 150;
  const dateWidth = 105;
  const rutWidth = 110;
  const iconSize = 70;
  const buttonWidth = 90;
  const orderNumberWidth = isMobile ? 90 : 110;
  const arrangementWidth = 80;
  const littleMessageStatus = 120;
  return {
    rateWidth,
    amountWidth,
    dateInputWidth,
    dateWidth,
    rutWidth,
    iconSize,
    statusWidth,
    orderingOperationStatus,
    cessionStatusWidth,
    buttonWidth,
    orderNumberWidth,
    littleMessageStatus,
    arrangementWidth,
  };
};

export default useWidth;
