import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Block from '@mui/icons-material/Block';
import { DicomDetailsCell, TextCell } from '../components/cells';
import { formatRut } from '../helpers';

const LegalRepresentativeColumns = [
  {
    field: 'name',
    type: 'string',
    headerName: 'Nombre',
    renderCell: ({ row }) => {
      const masterEntity = row.relatedPerson;
      return (
        <Stack direction="row" spacing={1}>
          <Typography>{masterEntity.name}</Typography>
          {masterEntity.riskBlacklist && (
            <Tooltip title="Empresa en la Blacklist">
              <Block loading sx={{ width: 15, height: 15 }} color="error" />
            </Tooltip>
          )}
        </Stack>
      );
    },
    sortable: true,
    flex: 2,
  },
  {
    field: 'rut',
    type: 'string',
    headerName: 'Rut',
    renderCell: ({ row }) => (
      <TextCell text={formatRut(row.relatedPerson.rut)} />
    ),
    sortable: true,
    flex: 0.7,
  },
  {
    field: 'partnerType',
    type: 'string',
    headerName: 'Tipo',
    renderCell: ({ row }) => (
      <Stack direction="column" spacing={0.7}>
        {row.isLegalRepresentative && <Typography>Rep. Legal</Typography>}
        {row.isPartner && <Typography>Socio</Typography>}
        {row.isCurrentSociety && <Typography>Sociedad Vigente</Typography>}
      </Stack>
    ),
    sortable: true,
    flex: 0.7,
  },
  {
    field: 'dicomDetails',
    headerName: 'Dicom',
    type: 'singleSelect',
    align: 'left',
    renderCell: ({ row }) => (
      <DicomDetailsCell masterEntity={row?.relatedPerson} />
    ),
    flex: 1,
  },
];

export default LegalRepresentativeColumns;
